import WalletUser from "../core/models/WalletUser";
import WalletUserManager from "../core/utils/WalletUserManager";
import WebServiceUtils from "../core/webservice/WebServiceUtils";

export default class WalletUserProfile {
    private static config = {
        headers: {
            "Content-Type": "application/json",
        },
    }
    private static getWalletUserProfileRequestBody(): any {
        return `{
            "query": "query { walletUserProfile{balanceTokens,usableBalanceTokens,bankAccountLinked,id}}"
        }`;
    }
    public static async getWalletUserProfile() {
        const response = await WebServiceUtils.post(
            this.getWalletUserProfileRequestBody(),
            this.config,
            process.env.REACT_APP_GRAPH_QL_URL
        );
        if (response.success) {
            try {
                if (response.data) {
                    WalletUserManager.shared().setWalletUser(new WalletUser(response.data.data.walletUserProfile))
                   return Promise.resolve(new WalletUser(response.data.data.walletUserProfile))
                  }
            } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }
}