// import SessionManager from '../../auth/SessionManager';
import BrandWalletAll from '../models/BrandWalletAll';
import WalletUser from '../models/WalletUser';
import TextUtils from './TextUtils';

export default class WalletUserManager {

  public static shared(): WalletUserManager {
    if (!WalletUserManager.sWalletUserManager) {
      WalletUserManager.sWalletUserManager = new WalletUserManager();
    }
    return WalletUserManager.sWalletUserManager;
  }

  private static sWalletUserManager: WalletUserManager | null;

  // get wallet user
  private mWalletUser: WalletUser | undefined | null;
  public get walletUser() {
    return this.mWalletUser;
  }

  // set wallet user
  public setWalletUser(walletUser: WalletUser | undefined) {
    this.mWalletUser = walletUser
  }

  // update wallet user
  public updateWalletUser(updatedWalletUser: WalletUser) {
    this.setWalletUser(updatedWalletUser)
  }

  // reset wallet user
  public resetWalletUser() {
    this.setWalletUser(undefined)
  }


  private mMoveToMasterWalletPage: boolean;
  public get moveToMasterWalletPage() {
    return this.mMoveToMasterWalletPage;
  }

  public setMoveToMasterWalletPage(flag: boolean) {
    this.mMoveToMasterWalletPage = flag
  }

}
