import BankAccount from "../core/models/BankAccount";
import PrimaryBankAccount from "../core/utils/PrimaryBankAccount";
import WalletUserManager from "../core/utils/WalletUserManager";
import WebServiceUtils from "../core/webservice/WebServiceUtils"

export default class BankDetails {
    private static config = {
        headers: {
            "Content-Type": "application/json",
        },
    }
    private static getBankAccountDetailsRequestBody(): any {
        return `{"query": "query($filter: BankAccountFilter,$sort:BankAccountSorter,$limit: Int!,$offset: Int){bankAccounts(filter: $filter, sort:$sort, limit: $limit,offset: $offset){data{id, accountNumber, routingNumber, accountType,logo,isPrimary,bankName}}}","variables": {"offset": 0,"limit": 1,"filter": {"isPrimary": {"eq": true}}}
      }`;
    }
    private static getWalletUserProfileRequestBody(): any {
        return `{"query": "query { walletUserProfile{name,balanceTokens,usableBalanceTokens}}"}`;
    }
    public static async getBankAccountDetails() {
        const response = await WebServiceUtils.post(
            this.getBankAccountDetailsRequestBody(),
            this.config,
            process.env.REACT_APP_GRAPH_QL_URL
        );
        if (response.success) {
            try {
                if (response.data && response.data.data && response.data.data.bankAccounts &&
                    response.data.data.bankAccounts.data) {
                    if (response.data.data.bankAccounts.data.length > 0) {
                        const data = new BankAccount(response.data.data.bankAccounts.data[0]);
                        PrimaryBankAccount.shared().setPrimaryBankAccount(data);
                        WalletUserManager.shared().setMoveToMasterWalletPage(true);
                        return Promise.resolve(data);
                    }else{
                        WalletUserManager.shared().setMoveToMasterWalletPage(false);
                        return Promise.resolve(-1);
                    }
                }
            } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }

    public static async getWalletUserProfile() {
        const response = await WebServiceUtils.post(
            this.getWalletUserProfileRequestBody(),
            this.config,
            process.env.REACT_APP_GRAPH_QL_URL
        );
        if (response.success) {
            try {
                if (response.data && response.data.data && response.data.data.walletUserProfile) {
                    return Promise.resolve(response.data.data.walletUserProfile.name);
                }
            }
            catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }
}