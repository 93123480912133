export default class WalletUser {
    private mBalanceTokens: number;
    public get balanceTokens() {
        return this.mBalanceTokens
    }
    private mBankAccountLinked: boolean;
    public get bankAccountLinked() {
        return this.mBankAccountLinked
    }
    private mUserId: number;
    public get userId() {
        return this.mUserId
    }
    private mUsableBalanceTokens: number;
    public get usableBalanceTokens() {
        return this.mUsableBalanceTokens
    }
    constructor(params: any) {
        if (params?.usableBalanceTokens) {
            this.mBalanceTokens = params.balanceTokens
            this.mBankAccountLinked = params.bankAccountLinked
            this.mUsableBalanceTokens = params.usableBalanceTokens
            this.mUserId = params.id
        } else {
            this.mBalanceTokens = parseInt(params.balanceTokens)
            if (params.bankAccountLinked == "true") {
                this.mBankAccountLinked = true;
            } else {
                this.mBankAccountLinked = false;
            }
            this.mUserId = parseInt(params.userId)
            this.mUsableBalanceTokens = parseInt(params.balanceTokens)
        }
    }
}