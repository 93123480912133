export default class Strings {
  /* Common */
  public static DOLLAR_SYMBOL = "$";
  public static TOKEN_SYMBOL = "t ";
  public static AMOUNT_UPPERLIMIT = 10000000;
  /* Error messages */
  public static DEFAULT_ERROR_MSG = "Something went wrong, Please try again.";
  public static NETWORK_ERROR = "Lost internet connection. Please check and try again.";
  public static UNAUTHORIZED_ERROR = "unauthorized";
  public static BEARER_TOKEN_NOT_AVAILABLE_ERROR = "Please include valid access_token value in the Authorization header field as an HTTP bearer authorization scheme.";
  public static MISSING_SEND_OTP_PARAMS_ERROR = "Please provide email."
  public static MISSING_VERIFY_OTP_PARAMS_ERROR = "Please provide email and otp."
  public static REQUEST_PARAMS_MISSING = "Request parameters missing."

  private constructor() { }
}
