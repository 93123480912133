import { Box, Typography } from "@mui/material";
import React, { useState, useContext, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";
import ContextService from "../../core/utils/ContextService";
import WalletUserManager from "../../core/utils/WalletUserManager";
import { ApiError } from "../../core/webservice/ApiError";
import Navigation from "../../navigation/Navigation";
import {
  NavigationProps,
  NavigationState,
} from "../../navigation/Navigation.types";
import AuthService from "../../services/AuthService";
import BankDetails from "../../services/BankDetails";
import withRouter from "../../withRouter";
import AuthLayout from "../common/authLayout/AuthLayout";
import { PrimaryBtn } from "../common/button/PrimaryBtn";
import "./otp.scss";

interface IProps {
  router: NavigationProps;
  state: NavigationState;
  onLoginSuccess(): void;
}
function Otp(props: IProps) {
  let pIsOtpGenerated = false;
  if (props.router.location.state && props.router.location.state.Email) {
    pIsOtpGenerated = true;
  }

  const [isOtpGenerated, setIsOtpGenerated] =
    useState<boolean>(pIsOtpGenerated);
  const context = useContext(ContextService);
  const [OTP, setOTP] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOTP, setLoadingOTP] = useState<boolean>(false);
  const [wrongOtp, setWrongOtp] = useState<boolean>(false);
  const [resendOtp, setResendOtp] = useState<boolean>(false);

  const keyDownHandler = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        submitHandler(event);
      }
    },
    [OTP]
  );

  const moveCursorAtFirst = () => {
    document.getElementsByTagName("input")[0].focus();
  };
  useEffect(() => {
    if (!isOtpGenerated) {
      Navigation.toLogin(props.router);
      return;
    }
    moveCursorAtFirst();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  const submitHandler = (e: any): void => {
    e.preventDefault();
    setLoading(true);
    verifyOTP(OTP);
  };
  const verifyOTP = async (otp?: any): Promise<void> => {
    try {
      const obj = await AuthService.verifyAuthCodeByEmail(props.router.location.state.Email, otp);
      if (obj.params) {
        let data = BankDetails.getBankAccountDetails();
        data.then((obj: any) => {
          props.onLoginSuccess();
        });
      }
    } catch (error: any) {
      setWrongOtp(true);
      setOTP("");
      setLoading(false);
      moveCursorAtFirst();
    }
  };

  const otpSet = (otp: any): void => {
    setOTP(otp);
  };

  const emailreSendBtn = (): void => {
    setLoadingOTP(true);

    setTimeout(() => {
      setLoadingOTP(false);
    }, 3000);

    setWrongOtp(false);
    setResendOtp(true);
    AuthService.sendAuthCodeByEmail(props.router.location.state.Email)
      .then((isOtpSent: boolean) => {
        console.log("OTP Resend");
      })
      .catch((error: ApiError) => {
        console.log("OTP Error");
      });
  };


  return (
    <AuthLayout>
      <Box className="otpBox">
        <Typography className="authHeading" variant="h2">
          ENTER CODE
        </Typography>
        <Typography className="authSubHeading">
          Check your email for a 4 digit code
        </Typography>
        <Box className="otpInputs">
          <OtpInput
            value={OTP}
            onChange={(otp: any) => otpSet(otp)}
            containerStyle={{}}
            inputStyle={{
              margin: "0",
              backgroundColor: "#fff",
              // border: "1px solid #000",
              width: "100%",
              height: "80px",
              maxWidth: "98px",
              color: "#000",
              borderRadius: 10,
              fontSize: 24,
              fontFamily: "Bebas",
              fontWeight: "400",
              marginRight: wrongOtp ? "22px" : "8%",
              border: wrongOtp
              ? "1px solid #ff0000"
              : "1px solid #000",
          }}
            inputType="number"
            shouldAutoFocus={true}
            renderInput={(props: any) => <input {...props} className={wrongOtp ? "otpbdr" : "otpcls"} />}
          />
        </Box>

        <Box>
          <div className="resendWrapper">
            Didn’t receive the code?
            <span className="resend" onClick={emailreSendBtn}>
              Resend
            </span>
          </div>
        </Box>
        <Box>
          <div className="errorMessage">
            {loadingOTP && (
              <div>Code has been resend to your email address</div>
            )}
            {wrongOtp && <div>Incorrect/expired Verification Code</div>}
          </div>
        </Box>
        <Box className="actionBox">
          <PrimaryBtn
            buttonWidth="198px"
            disableBtn={OTP.length != 4}
            label="Continue"
            onClickEvent={submitHandler}
            loading={loading}
          />
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default withRouter(Otp);
