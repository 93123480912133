interface BankAccountType {
    id: number;
    accountNumber: string;
    routingNumber: string;
    accountType: string;
    logo: string;
    isPrimary: boolean;
    bankName:string;
}
export default class BankAccount {
    private mId: number;
    public get id() {
        return this.mId;
    }
    private mAccountNumber: string;
    public get accountNumber() {
        return this.mAccountNumber;
    }
    private mRoutingNumber: string;
    public get routingNumber() {
        return this.mRoutingNumber;
    }
    private mAccountType: string;
    public get accountType() {
        return this.mAccountType;
    }
    private mLogo: string;
    public get logo() {
        return this.mLogo;
    }
    private mIsPrimary: boolean;
    public get isPrimary() {
        return this.mIsPrimary;
    }
    private mBankName: string;
    public get bankName() {
        return this.mBankName;
    }
    constructor(params: BankAccountType) {
        this.mId = params.id
        this.mAccountNumber = params.accountNumber
        this.mRoutingNumber = params.routingNumber
        this.mAccountType = params.accountType
        this.mLogo = params.logo
        this.mIsPrimary = params.isPrimary
        this.mBankName = params.bankName
    }
}