import Strings from "./Strings";
import GeneralUtils from "./GeneralUtils";

export default class TextUtils {
  public static isEmpty(text: string | undefined | null): boolean {
    if (text && text === 'null') {
      return true;
    }
    return !text || /^\s*$/.test(text);
  }

  public static toString(anyData: any): string {
    return "" + anyData;
  }

  public static stringCompare(text1: string, text2: string): number {
    if (text1 === text2) return 0;
    else if (text1 > text2) return 1;
    else return -1;
  }

  public static formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

  public static stripNonNumeric(text: string): string {
    if (TextUtils.isEmpty(text)) {
      return text;
    } else {
      return text.replace("[^0-9]", "");
    }
  }

  public static isDigitsOnly(text: string): boolean {
    return /^\d+$/.test(text);
  }

  public static isNonZeroDigitsOnly(text: string): boolean {
    return new RegExp("^[1-9]+$").test(text);
  }

  public static isDecimal(text: string): boolean {
    return /^\d+(\.\d{0,2})?$/.test(text);
  }

  public static decimalRoundOff(num: number, uptoPlace: number) {
    if (num) {
      return num.toFixed(uptoPlace);
    }
    return num;
  }

  public static trimString(originalString: string | undefined) {
    if (originalString && originalString.length > 0) {
      var newString = originalString.replace(/^\s+|\s+$/g, "");
      return newString;
    }
    return originalString;
  }

  public static capitalize(word: string | undefined) {
    if (word && word.length > 0) {
      const lower = word.toLowerCase();
      return word.charAt(0).toUpperCase() + lower.slice(1);
    }
    return word;
  }

  public static toDollar(amountInCoins: any): string {
    const dollarStr = `${GeneralUtils.toAmount(amountInCoins)}`
    const formattedCurrencyAmt = GeneralUtils.numberFormatWithLocale(dollarStr, true);
    return Strings.DOLLAR_SYMBOL + formattedCurrencyAmt; //$1,2033.00
  }

}
