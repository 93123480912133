import queryString from "query-string"
import TextUtils from "./TextUtils"

export type IQueryParams = {
    page: number,
    searchQuery?: string
}

export default class QueryParamUtils {
    public static getQueryParams(urlSearchString: string | null | undefined): IQueryParams {
        let page = 0
        let searchQuery: string = ''
        try {
            const parsedQs = queryString.parse(urlSearchString, { arrayFormat: 'index', parseNumbers: true })
            if (parsedQs) {
                // page
                let qPage = (parsedQs.page) ? parsedQs.page : 0
                if (isNaN(Number(qPage))) {
                    qPage = 0
                }
                page = Number(qPage)
                if (parsedQs.searchQuery) {
                    searchQuery = parsedQs.searchQuery as string
                }
            }
        } catch (e: any) {

        }
        const queryParams = {
            page, searchQuery
        }
        return queryParams
    }

    public static getQueryString(newQueryParams: IQueryParams | null | undefined): string {
        if (!newQueryParams) return ''
        let newQS = queryString.stringify({ page: newQueryParams.page }, { arrayFormat: 'index' });
        let newQS2 = queryString.stringify({ searchQuery: newQueryParams.searchQuery }, { arrayFormat: 'index' });
        if (newQS && !TextUtils.isEmpty(newQS)) {
            if (newQueryParams.searchQuery.length != 0) {
                newQS = '?' + newQS + '&' + newQS2;
            } else {
                newQS = '?' + newQS
            }
        } else {
            newQS = ''
        }
        return newQS
    }
}