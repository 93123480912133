import { NavigationParams, NavigationProps } from "./Navigation.types";
import AppRoutes from "../routes";
import WalletUserManager from "../core/utils/WalletUserManager";
import SessionManager from "../core/utils/SessionManager";
import BankDetails from "../services/BankDetails";
import QueryParamUtils from "../core/utils/QueryParamUtils";

export enum transferHistoryType {
  BRANDWALLET = "brand-wallet",
  BANKTRANSFER = "bank-transfer",
}
export enum transferType {
  ALL = "all",
  IN = "in",
  OUT = "out",
}
export enum walletType {
  All = "all",
  VIRTUAL = "virtual",
  LINKED = "linked",
}

export default class Navigation {
  /* navigate to home page */
  public static toHome(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.ROOT,
    });
  }

  public static logOut(router: NavigationProps) {
    const { navigate } = router;
    navigate(AppRoutes.ROOT, { replace: true });
  }

  public static forceLogout() {
    WalletUserManager.shared().resetWalletUser();
    SessionManager.shared().clearSession();
    window.location.reload();
  }

  /* navigate back to page */
  public static back(router: NavigationProps) {
    const { navigate } = router;
    navigate(-1);
  }

  public static refreshPage(router: NavigationProps) {
    const { navigate } = router;
    navigate(0);
  }

  /* navigate to otp page */
  public static toOtp(router: NavigationProps, params: any) {
    const { navigate } = router;
    navigate(
      {
        pathname: AppRoutes.OTP,
      },
      { state: params }
    );
  }

  /* navigate to login page */
  public static toLogin(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.LOGIN,
    });
  }

  /* navigate to SETUP WALLET page */
  public static toSetupWallet(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.SETUPWALLET,
    });
  }

  /* navigate to Edit Wallet page */
  public static toEditWallet(
    router: NavigationProps,
    id: number,
    params: BankDetails
  ) {
    const { navigate } = router;
    navigate(
      {
        pathname: AppRoutes.SETUPWALLET + "/" + id,
      },
      { state: params }
    );
  }
  /* navigate to Mater Wallet page */
  public static toMaterWallet(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.MASTERWALLET,
    });
  }

  /* navigate to Brand Wallet page */
  public static toBrandWallet(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.BRAND_WALLET,
    });
  }

  /* navigate to Brand Wallet Detail page */

  public static toBrandWalletDetail(
    params: NavigationParams,
    type: walletType,
    id: string,
    transferDetail: transferType,
    state: any
  ) {
    const { navigate } = params.router;
    let qs = QueryParamUtils.getQueryString(params.queryParams);
    navigate(
      AppRoutes.BRAND_WALLET +
      "/" +
      type +
      "/" +
      `${id}` +
      "/" +
      transferDetail +
      qs,
      {
        replace: params.toBeReplaced,
        state: {
          id: state?.id,
          email: state?.emailId || state?.email,
          name: state?.name,
        },
      }
    );
  }

  /* navigate to brand wallet page */
  public static toBrandWalletType(params: NavigationParams, type: walletType) {
    const { navigate } = params.router;
    let qs = QueryParamUtils.getQueryString(params.queryParams);
    navigate(AppRoutes.BRAND_WALLET + "/" + type + qs, {
      replace: params.toBeReplaced,
    });
  }

  /* navigate to Transfer History page */
  public static toTransferHistory(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.TRANSFER_HISTORY,
    });
  }

  public static toTransferHistoryDetail(
    params: NavigationParams,
    type: transferHistoryType
  ) {
    const { navigate } = params.router;
    let qs = QueryParamUtils.getQueryString(params.queryParams);
    navigate(AppRoutes.TRANSFER_HISTORY + "/" + type + qs, {
      replace: params.toBeReplaced,
    });
  }





  /* navigate to Brand Wallet Approval page */
  public static toApproveBrandWallet(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.APPROVE_BRAND_WALLET,
    });
  }
  /* navigate to brand wallet Approval page */
  public static toApproveBrandWalletType(params: NavigationParams, type: walletType) {
    const { navigate } = params.router;
    let qs = QueryParamUtils.getQueryString(params.queryParams);
    navigate(AppRoutes.APPROVE_BRAND_WALLET + "/" + type + qs, {
      replace: params.toBeReplaced,
    });
  }







}
