import { Box } from "@mui/system";
import IngeniaIcon from "../../../statics/svgs/IngeniaIcon";
import HeaderLogo from "../headerlogo/HeaderLogo";
import "./authLayout.scss"
export default function AuthLayout({ children }: any) {
  return (
    <Box className="authWrapper">
      <Box className="authLeft">
        <Box className="logoBox">
          <HeaderLogo />
        </Box>
        {children}
      </Box>
      <Box className="authRight">
       <IngeniaIcon className="heartImg"  />
      </Box>
    </Box>
  );
}
