import WebServiceUtils from "../core/webservice/WebServiceUtils"
import { ApiError } from "../core/webservice/ApiError"
import Strings from "../core/utils/Strings"
import TextUtils from "../core/utils/TextUtils"
import SessionManager from "../core/utils/SessionManager"
import ApiEndpoints from "./ApiEndpoints"
import WalletUser from "../core/models/WalletUser"
import WalletUserManager from "../core/utils/WalletUserManager"

export default class AuthService {
  public static async sendAuthCodeByEmail(email: string) {
    if (TextUtils.isEmpty(email)) {
      return Promise.reject(
        new ApiError(400, Strings.MISSING_SEND_OTP_PARAMS_ERROR)
      )
    }

    const params = new URLSearchParams()
    params.append("restore_type", "email")
    params.append("email_account", email)
    params.append("client_id", "pnws")
    params.append("user_role", "Merchant") // Merchant

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    const apiUrl =
      process.env.REACT_APP_WALLET_OAUTH_URL + "/" + ApiEndpoints.WALLET_RESTORE
    console.log("URl" + apiUrl)
    const response = await WebServiceUtils.post(params, config, apiUrl)

    if (response.success) {
      const httpStatus =
        response.response && response.response.status
          ? response.response.status
          : 200
      console.log("Code" + httpStatus)
      try {
        let isOtpSent = false
        if (response.data && httpStatus === 201) {
          isOtpSent = true
        }
        return Promise.resolve(isOtpSent)
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response)
  }

  public static async verifyAuthCodeByEmail(email: string, otp: string) {
    if (TextUtils.isEmpty(email) || TextUtils.isEmpty(otp)) {
      return Promise.reject(
        new ApiError(400, Strings.MISSING_VERIFY_OTP_PARAMS_ERROR)
      )
    }

    const params = new URLSearchParams()
    const code = email + "|" + otp
    params.append("code", code)
    params.append("grant_type", "authorization_code")
    params.append("client_id", "pnws")

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }

    const apiUrl =
      process.env.REACT_APP_WALLET_OAUTH_URL + "/" + ApiEndpoints.WALLET_TOKEN
    const response = await WebServiceUtils.post(params, config, apiUrl)
    if (response.success) {
      try {
        if (response.data) {
          WalletUserManager.shared().setWalletUser(new WalletUser(response.data.params))
          SessionManager.shared().saveUserDetails(response.data)
          return Promise.resolve(response.data)
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response)
  }

  public static async refreshToken() {

    const params = new URLSearchParams()
    const refreshToken = SessionManager.shared().refreshToken;

    params.append('refresh_token', refreshToken)
    params.append('grant_type', 'refresh_token')
    params.append('client_id', 'pnws')

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const apiUrl = process.env.REACT_APP_API_BASE_URL + "/" + ApiEndpoints.REFRESH_TOKEN
    const response = await WebServiceUtils.post(
      params,
      config,
      apiUrl
    );

    if (response.success) {
      try {
        if (
          response.data
        ) {
          return Promise.resolve(response.data);
        }
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }
}
