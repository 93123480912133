import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import GeneralUtils from '../../core/utils/GeneralUtils';
import { ApiError } from "../../core/webservice/ApiError";
import Navigation from "../../navigation/Navigation";
import {
  NavigationProps,
  NavigationState,
} from "../../navigation/Navigation.types";
import AuthService from "../../services/AuthService";
import withRouter from "../../withRouter";
import AuthLayout from "../common/authLayout/AuthLayout";
import { PrimaryBtn } from "../common/button/PrimaryBtn";
import { TextFields } from "../common/inputfield/TextFields";
import "./login.scss";
import { hideMessage, showErrorMessage } from "../common/customeToast/MessageNotifier";

interface IProps {
  router: NavigationProps;
  states: NavigationState;
}

function Login(props: IProps) {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const validateEmail = (e: any): void => {
    setEmail(e);
    if (!GeneralUtils.isValidEmailId(e)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  function loginApiCall() {
    AuthService.sendAuthCodeByEmail(email)
      .then((isOtpSent: boolean) => {
        if (isOtpSent) {
          Navigation.toOtp(props.router, { Email: email });
        }
      })
      .catch((error: ApiError) => {
        setLoading(false);
      });
  }

  const submitHandler = (e: any) => {
    e.preventDefault();
    hideMessage()
    const emailToCompare = email.toLowerCase()
    if(process.env.REACT_APP_PRIMARY_EMAIL !== emailToCompare) {
      showErrorMessage('Email ID is not registered for Master Wallet.')
    } else {
      setLoading(true);
      loginApiCall();
    }
    // setTimeout(() => {
    //   setLoading(false);
    //   Navigation.toOtp(props.router, { Email: email });
    // }, 3000);
  };

  function validateForm() {
    return email.length > 0 && GeneralUtils.isValidEmailId(email)
  }
  
  return (
    <AuthLayout>
      <Box className="loginBox">
        <Typography className="authHeading" variant="h2">
          WELCOME
        </Typography>
        <TextFields
          placeholder="your email address"
          onChange={validateEmail}
          value={email}
          fieldLabel="ENTER YOUR EMAIL"
          onEnterTapped={(e: any) => {
            if (validateForm()) {
              submitHandler(e)
            }
         }}
        />
        <Box className="actionBox">
          <PrimaryBtn
            buttonWidth="198px"
            disableBtn={emailError ? true : false}
            label="Continue"
            onClickEvent={submitHandler}
            loading={loading}
          />
        </Box>
      </Box>
    </AuthLayout>
  );
}
export default withRouter(Login);
