import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
export enum SkeletonType {
  TABLE = "Table",
  BOX = "Box"
}
interface Iprops {
  tbodyRow?: number;
  height?: number | string;
  width?: number | string;
  theadColum?: number;
  varient: SkeletonType;
  boxCount?: number
}
const SkeletonLoader = (props: Iprops) => {
  return (
    <>
      {props.varient == SkeletonType.TABLE &&
        <TableBody>
          <TableRow>
            <TableCell colSpan={props.theadColum}>
              {Array(props.tbodyRow)
                .fill("")
                .map((e, i) => (
                  <Skeleton height={props.height} key={i} />
                ))}
            </TableCell>
          </TableRow>
        </TableBody>
      }
      {props.varient == SkeletonType.BOX &&
        <Box width={"100%"}>
          {Array(props.boxCount)
            .fill("")
            .map((e, i) => (
              <Skeleton height={props.height} key={i} />
            ))}
        </Box>
      }
    </>
  );
};
export default SkeletonLoader;
