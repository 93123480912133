import { matchRoutes } from "react-router-dom";

export default class AppRoutes {
    public static readonly ROOT = "/";
    public static readonly LOGIN = "/login";
    public static readonly OTP = "/otp";

    public static readonly SETUPWALLET = "/setup-wallet";
    public static readonly EDITWALLET = "/setup-wallet/:id";

    public static readonly MASTERWALLET = "/master-wallet";

    public static readonly BRAND_WALLET = "/brand-wallet";
    public static readonly BRAND_WALLET_TYPE = "/brand-wallet/:brandWalletType";
    public static readonly BRAND_WALLET_DETAIL = `/brand-wallet/:brandWalletType/:brandId/:transferType`;

    public static readonly TRANSFER_HISTORY = "/transfer-history";
    public static readonly TRANSFER_HISTORY_TYPE = `/transfer-history/:transferHistoryType`;
 
    
    public static readonly APPROVE_BRAND_WALLET = "/approve-brand-wallet";
    public static readonly APPROVE_BRAND_WALLET_TYPE = "/approve-brand-wallet/:brandWalletType";

    private constructor() { }

    private static routes = [
      {path: AppRoutes.ROOT, strict: true, exact: true},
      {path: AppRoutes.OTP, strict: true, exact: true},
      {path: AppRoutes.LOGIN, strict: true, exact: true},
      {path: AppRoutes.SETUPWALLET, strict: true, exact: true},
      {path: AppRoutes.EDITWALLET, strict: true, exact: true},
      {path: AppRoutes.MASTERWALLET, strict: true, exact: true},
      {path: AppRoutes.BRAND_WALLET, strict: true, exact: true},
      {path: AppRoutes.BRAND_WALLET_TYPE, strict: true, exact: true},
      {path: AppRoutes.BRAND_WALLET_DETAIL, strict: true, exact: true},
      {path: AppRoutes.TRANSFER_HISTORY, strict: true, exact: true},
      {path: AppRoutes.TRANSFER_HISTORY_TYPE, strict: true, exact: true},
      {path: AppRoutes.APPROVE_BRAND_WALLET, strict: true, exact: true},
      {path: AppRoutes.APPROVE_BRAND_WALLET_TYPE, strict: true, exact: true}
    ]
    
    public static matchRoute(routes: any[], location: any) {
        const matchedRoutes = matchRoutes(routes, location.pathname)
        if (!Array.isArray(matchedRoutes) || matchedRoutes.length === 0) {
          return false
        }
        return true
      }
    public static isRouteMatched(location: any) {
        let isRouteValid = this.matchRoute(AppRoutes.routes,location)
        return isRouteValid
      }

}