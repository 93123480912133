import TextUtils from "./TextUtils";
import compareVersions from "compare-versions";

export default class GeneralUtils {
  private constructor() { }

  public static makeACall(phoneNum: string | undefined) {
    if (!TextUtils.isEmpty(phoneNum)) {
      window.location.href = "tel:" + phoneNum;
    }
  }

  public static isAndroidOS(): boolean {
    let userAgent = navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  public static isSafariBrowser(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public static toAmount = (value: any, defValue = 0) => {
    return value ? (value / 100).toFixed(2) : defValue;
  }

  public static toTokens = (value: any, defValue = 0) =>
    value && parseFloat(value) ? Number((parseFloat(value) * 100).toFixed(2)) : defValue;

  public static forceAppUpdateRequired(latestVersion: string, latestBuildVersion: string): boolean {
    var storeAppVersion = latestVersion;
    var currentAppV = process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : "";
    var storeAppBuildVersion = latestBuildVersion;
    var currentAppBuildV = process.env.REACT_APP_BUILD_VERSION
      ? process.env.REACT_APP_BUILD_VERSION
      : "";

    if (
      !TextUtils.isEmpty(storeAppVersion) &&
      !TextUtils.isEmpty(currentAppV)
    ) {
      // let versionComparator = compareVersions(currentAppV, storeAppVersion);
      // if (versionComparator === -1) {
      //   return true;
      // }
    }

    if (
      !TextUtils.isEmpty(storeAppBuildVersion) &&
      !TextUtils.isEmpty(currentAppBuildV)
    ) {
      // let buildComparator = compareVersions(
      //   currentAppBuildV,
      //   storeAppBuildVersion
      // );
      // if (buildComparator == -1) {
      //   return true;
      // }
    }

    return false;
  }

  public static numberFormatWithLocale(x: string | undefined, withFractionDigits: boolean): string {
    if (x && !TextUtils.isEmpty(x)) {
      const userLocale = "en-US";
      if (withFractionDigits) {
        return new Intl.NumberFormat(userLocale, { minimumFractionDigits: 2 }).format(Number(x))
      }
      return new Intl.NumberFormat(userLocale, {}).format(Number(x))

    }
    if (!x) return "0.00";
    return x;
  }

  public static validEmail: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static url = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

  public static getAppVersion(): string {
    return `v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BUILD_VERSION}`
  }

  public static isValidEmailId(text: string): boolean {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return regexp.test(text);
  }

  public static getTotalPageCount(totalNoOfRecords: number, pageSize: number): number {
    return totalNoOfRecords % pageSize == 0
      ? totalNoOfRecords / pageSize
      : Math.trunc(totalNoOfRecords / pageSize) + 1;
  }

}
