import { Navigate } from "react-router-dom";
import WalletUserManager from "./core/utils/WalletUserManager";
// import Navigation from "./navigation/Navigation";
import AppRoutes from "./routes";
import withRouter from "./withRouter";
const Protected = ({ isLoggedIn, path, children, router }) => {
    if (path === AppRoutes.ROOT || path === AppRoutes.OTP) {
        if (isLoggedIn) {
            if (WalletUserManager.shared().moveToMasterWalletPage) {
                return <Navigate to={AppRoutes.MASTERWALLET} replace />;
                // Navigation.toMaterWallet(router);
            } else {
                return <Navigate to={AppRoutes.SETUPWALLET} replace />;
                // Navigation.toSetupWallet(router);
            }
        }
        return children;
    }
    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
        // Navigation.toHome(router)
    }
    return children;
};
export default withRouter(Protected);