import { Suspense, lazy, useEffect, useState } from "react";
import "./common.scss"
import "./App.css";
import SessionManager from "./core/utils/SessionManager";
import { Navigate, Route, Routes } from "react-router-dom";
import AppRoutes from "./routes";
import Login from "./components/login/Login";
import Otp from "./components/otp/Otp";
import withRouter from "./withRouter";
import { NavigationProps, NavigationState } from "./navigation/Navigation.types";
import MessageNotifier from "./components/common/customeToast/MessageNotifier";
import LandingComponent from "./components/landing/LandingComponent";
import Protected from "./Protected";
import MainSkeletonLoader from "./components/common/skeletonLoader/MainSkeletonLoader";

const SetupWallet = lazy(() => import('./components/setupWallet/SetupWallet'));
const MasterWallet = lazy(() => import('./components/masterWallet/MasterWallet'));
const BrandWallet = lazy(() => import('./components/brandWallet/BrandWallet'));
const BrandWalletDetail = lazy(() => import('./components/brandWallet/brandWalletDetail/BrandWalletDetail'));
const TransferHistory = lazy(() => import('./components/transferHistory/TransferHistory'));
const Error = lazy(() => import('./components/error/Error'));
const ApproveBrandWallet = lazy(() => import('./components/approveBrandWallet/ApproveBrandWallet'));
const CoreLayout = lazy(() => import('./components/common/coreLayout/CoreLayout'));

interface IProps {
  router: NavigationProps;
  states: NavigationState;
}

function App(props: IProps): JSX.Element {
  const [accessTokenValid, setAccessTokenValid] = useState<boolean>(SessionManager.shared().isTokenAvailable());
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  const handleInitialDataLoadSuccess = () => {
    setAccessTokenValid(SessionManager.shared().isTokenAvailable())
    setIsInitialDataLoaded(true)
  }

  const handleInitialDataLoadFailure = () => { }

  const handleOnLoginSuccess = () => {
    setAccessTokenValid(SessionManager.shared().isTokenAvailable())
  }

  const handleOnLogoutSuccess = () => {
    setAccessTokenValid(SessionManager.shared().isTokenAvailable())
  }

  useEffect((): void => {
    if (SessionManager.shared().accessToken) {
      setAccessTokenValid(true);
    } else {
      setAccessTokenValid(false);
    }
  }, []);

  const unknownRoute = () => {
    return (
      <Routes>
        <Route path="*" element={<Error />} />
      </Routes>
    );
  };

  const renderRoutes = () => {
    const isRouteValid = AppRoutes.isRouteMatched(props.router.location);
    if (!isRouteValid) {
      return unknownRoute();
    }
    return protectedRoute();
  };

  const elementsByPath = (path: string) => {
    switch (path) {
      case AppRoutes.SETUPWALLET:
        return <SetupWallet />;
      case AppRoutes.MASTERWALLET:
        return <MasterWallet />;
      case AppRoutes.BRAND_WALLET:
        return <BrandWallet />;
      case AppRoutes.BRAND_WALLET_DETAIL:
        return <BrandWalletDetail />;
      case AppRoutes.BRAND_WALLET_TYPE:
        return <BrandWallet />;
      case AppRoutes.EDITWALLET:
        return <SetupWallet />;
      case AppRoutes.TRANSFER_HISTORY:
        return <TransferHistory />;
      case AppRoutes.TRANSFER_HISTORY_TYPE:
        return <TransferHistory />;
      case AppRoutes.APPROVE_BRAND_WALLET:
        return <ApproveBrandWallet />;
      case AppRoutes.APPROVE_BRAND_WALLET_TYPE:
        return <ApproveBrandWallet />;
      default:
        return undefined;
    }
  };

  const renderElement = (path: string) => {
    return (
      <Suspense fallback={<MainSkeletonLoader />} >
        <Protected isLoggedIn={accessTokenValid} path={path}>
          <CoreLayout onLogoutSuccess={handleOnLogoutSuccess} >
            {elementsByPath(path)}
          </CoreLayout>
        </Protected>
      </Suspense>
    );
  };

  const protectedRoute = (): JSX.Element => {
    return (
      <Routes>
        <Route
          path={AppRoutes.ROOT}
          element={
            <Protected isLoggedIn={accessTokenValid} path={AppRoutes.ROOT}>
              <Login />
            </Protected>
          }
        />
        <Route
          path={AppRoutes.LOGIN}
          element={
            <Protected isLoggedIn={accessTokenValid} path={AppRoutes.LOGIN}>
              <Login />
            </Protected>
          }
        />
        <Route
          path={AppRoutes.OTP}
          element={
            <Protected isLoggedIn={accessTokenValid} path={AppRoutes.OTP}>
              <Otp onLoginSuccess={handleOnLoginSuccess} />
            </Protected>
          }
        />
        <Route
          path={AppRoutes.SETUPWALLET}
          element={renderElement(AppRoutes.SETUPWALLET)}
        />
        <Route
          path={AppRoutes.EDITWALLET}
          element={renderElement(AppRoutes.SETUPWALLET)}
        />

        <Route
          path={AppRoutes.MASTERWALLET}
          element={renderElement(AppRoutes.MASTERWALLET)}
        />

        <Route
          path={AppRoutes.BRAND_WALLET}
          element={<Navigate to={AppRoutes.BRAND_WALLET + "/all"} replace />}
        />
        <Route
          path={AppRoutes.BRAND_WALLET_TYPE}
          element={renderElement(AppRoutes.BRAND_WALLET_TYPE)}
        />
        <Route
          path={AppRoutes.BRAND_WALLET_DETAIL}
          element={renderElement(AppRoutes.BRAND_WALLET_DETAIL)}
        />

        <Route
          path={AppRoutes.TRANSFER_HISTORY}
          element={
            <Navigate
              to={AppRoutes.TRANSFER_HISTORY + "/brand-wallet"}
              replace
            />
          }
        />
        <Route
          path={AppRoutes.TRANSFER_HISTORY_TYPE}
          element={renderElement(AppRoutes.TRANSFER_HISTORY_TYPE)}
        />

        <Route
          path={AppRoutes.APPROVE_BRAND_WALLET}
          element={
            <Navigate
              to={AppRoutes.APPROVE_BRAND_WALLET + "/all"}
              replace
            />
          }
        />
        <Route
          path={AppRoutes.APPROVE_BRAND_WALLET_TYPE}
          element={renderElement(AppRoutes.APPROVE_BRAND_WALLET_TYPE)}
        />

        <Route path="*" element={<Error />} />
      </Routes>
    )
  }

  return (
    <div className="App">
      {isInitialDataLoaded ? renderRoutes() :
        <LandingComponent
          onInitialDataLoadSuccess={handleInitialDataLoadSuccess}
          onInitialDataLoadingFailure={handleInitialDataLoadFailure}
        />
      }
      <MessageNotifier />
    </div>
  )
}

export default withRouter(App);
