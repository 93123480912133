
export default class ApiEndpoints {

    public static WALLET_RESTORE = "restore";
    public static WALLET_TOKEN = "token";
    public static REFRESH_TOKEN = "token";

    private constructor() {
    }
 


}