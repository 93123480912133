import React from "react";
import { ApiError } from "../../core/webservice/ApiError";
import withRouter from "../../withRouter";
import { NavigationProps } from "../../navigation/Navigation.types";
import SessionManager from "../../core/utils/SessionManager";
import WalletUserProfile from "../../services/WalletUserProfile";
import MainSkeletonLoader from "../common/skeletonLoader/MainSkeletonLoader";
import BankDetails from "../../services/BankDetails";

interface LandingComponentProps {
  router: NavigationProps;
  onInitialDataLoadSuccess(): void;
  onInitialDataLoadingFailure(e: ApiError): void;
}

interface LandingComponentState {
  sLoadingError: boolean;
  sLoadingErrorTitle: string;
  sLoadingErrorMessage: string;
}

class LandingComponent extends React.Component<
  LandingComponentProps,
  LandingComponentState
> {
  constructor(props: LandingComponentProps) {
    super(props);
    this.state = {
      sLoadingError: false,
      sLoadingErrorTitle: "",
      sLoadingErrorMessage: "",
    };
  }

  componentDidMount() {
    if (SessionManager.shared().isTokenAvailable()) {
      // account exist or not
      // profile detail list
      Promise.all([WalletUserProfile.getWalletUserProfile(),BankDetails.getBankAccountDetails()]).then((obj) => {
        this.props.onInitialDataLoadSuccess();
      }).catch((apiError: ApiError) => {
        this.handleApiFailure(apiError);
      })

    } else {
      // navigate to login/root.
      this.props.onInitialDataLoadSuccess();
    }
  }

  private handleApiFailure = (apiError: ApiError) => {
    this.setState({
      sLoadingError: true,
      sLoadingErrorMessage:
        apiError && apiError.message ? apiError.message : "",
    });
    this.props.onInitialDataLoadingFailure(apiError);
  };

  render() {
    return (
      <>
        {!this.state.sLoadingError && <><MainSkeletonLoader /></>}
      </>
    );
  }
}

export default withRouter(LandingComponent);