import { InputAdornment, TextField, Typography, styled } from "@mui/material"
import "./inputField.scss";
type FieldType = "string" | "numeric" | "float" | "currency"

interface IInputField {
  fieldType?: FieldType
  fieldLabel?: string
  placeholder?: string
  value?: string | number
  helperText?: string
  startAdornment?: any 
  endAdornment?: any 
  type?: React.HTMLInputTypeAttribute
  marginLeft?: string
  maxLength?: number
  disabled?:boolean
  onChange?(val:  string): void
  onEnterTapped?(e:  string): void
  readOnlyFlag?:boolean
}

export const TextFields = (props: IInputField) => {
  const {
    fieldType = "string",
    fieldLabel = null,
    placeholder = null,
    helperText = null,
    startAdornment = null,
    endAdornment = null,
    type = "text",
    onChange = null,
    value = undefined,
    marginLeft = "0px",
    disabled = false,
    onEnterTapped = undefined
  } = props

  const pType =
    fieldType === "numeric" || fieldType === "float" || fieldType === "currency"
      ? "number"
      : type
  const pStartAdornment = fieldType == "currency" ? "$" : startAdornment
  const pPlaceholder = fieldType == "currency" ? "0.00" : placeholder

  const handleOnChange = (e: any) => {
    if (["numeric", "float", "currency"].includes(fieldType)) {
      let regex = /^[0-9\b]+$/
      if (["float", "currency"].includes(fieldType)) {
        regex = /^\d+(\.\d{0,2})?$/
      }
      if (e.target.value === "" || regex.test(e.target.value)) {
        const val = isNaN(e.target.valueAsNumber)
          ? undefined
          : e.target.valueAsNumber
        onChange(val)
      }
    } else {
      onChange(e.target.value)
    }
  }

  const renderStartAdornment = () => {
    if(!pStartAdornment) return null
    return (
      <InputAdornment position="start">
        {pStartAdornment &&
        (typeof pStartAdornment === "string" ||
          pStartAdornment instanceof String) ? (
          <Typography variant="h6" className="textAdormentStyle">
            {pStartAdornment}
          </Typography>
        ) : (
          pStartAdornment
        )}
      </InputAdornment>
    )
  }

  const renderEndAdornment = () => {
    if(!endAdornment) return null
    return (
      <InputAdornment position="end">
        {endAdornment &&
        (typeof endAdornment === "string" || endAdornment instanceof String) ? (
          <Typography variant="h6" className="textAdormentStyle">
            {endAdornment}
          </Typography>
        ) : (
          endAdornment
        )}
      </InputAdornment>
    )
  }

  return (
    <>
      <TextField
        variant="standard"
        className="inputField"
        sx={{ width: "100%", fontSize: "20px", marginLeft: marginLeft }}
        placeholder={pPlaceholder}
        value={value}
        helperText={helperText}
        type={pType}
        InputProps={{
          startAdornment: renderStartAdornment(),
          endAdornment: renderEndAdornment(),
          readOnly:props.readOnlyFlag
        }}
        onChange={handleOnChange}
        autoComplete="off"
        disabled={disabled}
        onKeyDown={(e: any) => {
          if(e.key === "Enter" && onEnterTapped) {
            onEnterTapped(e)
          }
        }}
      />
      {fieldLabel && (
        <Typography
          sx={{
            fontFamily: "Bebas",
            fontWeight: "400",
            letterSpacing: "0.1em",
            marginTop: "8px",
            fontSize: "20px",
            color: "#000",
          }}
        >
          {fieldLabel}
        </Typography>
      )}
    </>
  )
}
