import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

export default function LogoIcon(props: SvgIconProps) {
  var uProps = { ...props }
  uProps.viewBox = "0 0 112 79"
  uProps.width = "112"
  uProps.height = "79"
  return (
    <SvgIcon {...uProps} style={{ width: uProps.width, height: uProps.height }}>
      <path
        d="M56.1554 73.6375C73.5734 73.6375 87.6936 59.577 87.6936 42.2323C87.6936 24.8877 73.5734 10.8271 56.1554 10.8271C38.7373 10.8271 24.6172 24.8877 24.6172 42.2323C24.6172 59.577 38.7373 73.6375 56.1554 73.6375Z"
        fill="#B9B9B9"
      />
      <path
        opacity="0.15"
        d="M56.1591 10.8271C43.9775 10.8271 33.4171 17.7069 28.1641 27.7712C33.4171 37.8356 43.9775 44.7153 56.1591 44.7153C68.3408 44.7153 78.9011 37.8356 84.1542 27.7712C78.8962 17.7069 68.3408 10.8271 56.1591 10.8271Z"
        fill="url(#paint0_linear_1519_6269)"
      />
      <path
        d="M83.4911 52.0195C88.9195 36.9886 81.0835 20.4217 65.989 15.0162C50.8945 9.61064 34.2574 17.4135 28.829 32.4444C23.4006 47.4752 31.2365 64.0422 46.331 69.4477C61.4256 74.8532 78.0627 67.0504 83.4911 52.0195Z"
        fill="#DEDEDE"
      />
      <path
        d="M76.514 36.0002C80.8278 36.0002 84.3249 32.5179 84.3249 28.2223C84.3249 23.9266 80.8278 20.4443 76.514 20.4443C72.2002 20.4443 68.7031 23.9266 68.7031 28.2223C68.7031 32.5179 72.2002 36.0002 76.514 36.0002Z"
        fill="black"
      />
      <g opacity="0.1">
        <path
          d="M72.5938 34.9399C73.7486 35.6104 75.0831 36.0006 76.5155 36.0006C77.9479 36.0006 79.213 35.6308 80.3475 34.9968V33.0666L72.5938 22.9561V34.9399Z"
          fill="black"
        />
      </g>
      <path
        d="M79.6042 33.4643L73.3359 25.2881V56.8672H79.6042V33.4643Z"
        fill="url(#paint1_linear_1519_6269)"
      />
      <g opacity="0.1">
        <path
          d="M79.6042 52.9177L73.3359 46.3711V53.9702L73.4216 54.0678L73.3359 54.0759V57.6804H79.6042V52.9177Z"
          fill="black"
        />
      </g>
      <path
        d="M35.3372 19.0036C30.6219 19.0036 26.7994 15.1972 26.7994 10.5018C26.7994 5.80638 30.6219 2 35.3372 2C40.0525 2 43.875 5.80638 43.875 10.5018C43.875 15.1972 40.0525 19.0036 35.3372 19.0036Z"
        fill="black"
      />
      <g opacity="0.1">
        <path
          d="M39.4844 17.7332C38.2201 18.5186 36.7593 18.9755 35.1913 18.9755C33.6233 18.9755 32.2384 18.5423 30.9965 17.7999L31.336 15.9198L39.3146 3.69727L39.4844 17.7332Z"
          fill="black"
        />
      </g>
      <path
        d="M31.3918 17.9234L38.9609 5.05566V56.6986H31.3918V17.9234Z"
        fill="url(#paint2_linear_1519_6269)"
      />
      <g opacity="0.1">
        <path
          d="M31.3918 48.8078L38.9609 37.9609V50.5516L38.8575 50.7132L38.9609 50.7267V56.6988H31.3918V48.8078Z"
          fill="black"
        />
      </g>
      <path
        d="M22.9688 67.1896L55.5566 20.5332L87.3116 67.1896L45.2968 54.8679L70.6407 55.6089L55.5418 33.6351L34.2141 67.1896H22.9688Z"
        fill="black"
      />
      <g opacity="0.15">
        <path
          d="M73.9276 56.8061L55.5418 32.2119L32.9033 64.996L22.9688 67.1895H34.2141L55.5418 33.635L70.6407 55.6088L45.2968 54.8678L73.9276 56.8061Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1519_6269"
          x1="56.1591"
          y1="11.308"
          x2="56.1591"
          y2="37.1143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1519_6269"
          x1="76.4701"
          y1="30.2011"
          x2="76.4701"
          y2="52.4255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1B1B1B" />
          <stop offset="1" stop-color="#1B1B1B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1519_6269"
          x1="35.1764"
          y1="12.5168"
          x2="35.1764"
          y2="49.3395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1B1B1B" />
          <stop offset="1" stop-color="#1B1B1B" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
