import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

export default function IngeniaIcon(props: SvgIconProps) {
  var uProps = { ...props }
  uProps.viewBox = "194 214"
  uProps.width = "194"
  uProps.height = "214"
  uProps.fill = "none"
  return (
    <SvgIcon {...uProps} style={{ width: uProps.width, height: uProps.height }}>
      <path
        d="M189.525 119.679C189.525 169.319 149.006 209.6 98.9733 209.6C48.9409 209.6 8.42188 169.319 8.42188 119.679C8.42188 70.0397 48.9409 29.7588 98.9733 29.7588C149.006 29.7588 189.525 70.0397 189.525 119.679Z"
        stroke="#EBEBEB"
        stroke-width="7"
      />
      <path
        d="M159.684 101.14C172.548 101.14 182.977 90.7813 182.977 78.0031C182.977 65.225 172.548 54.8662 159.684 54.8662C146.819 54.8662 136.391 65.225 136.391 78.0031C136.391 90.7813 146.819 101.14 159.684 101.14Z"
        fill="white"
      />
      <g opacity="0.1">
        <path
          d="M147.984 97.9852C151.428 99.9797 155.408 101.14 159.68 101.14C163.951 101.14 167.724 100.04 171.107 98.1544V92.4125L147.984 62.3369V97.9852Z"
          fill="white"
        />
      </g>
      <path
        d="M168.896 93.5969L150.203 69.2754V163.213H168.896V93.5969Z"
        fill="url(#paint0_linear_7245_37331)"
      />
      <g opacity="0.1">
        <path
          d="M168.896 151.463L150.203 131.989V154.594L150.459 154.884L150.203 154.909V165.631H168.896V151.463Z"
          fill="white"
        />
      </g>
      <path
        d="M36.8829 50.5802C22.8212 50.5802 11.422 39.2574 11.422 25.2901C11.422 11.3228 22.8212 0 36.8829 0C50.9445 0 62.3438 11.3228 62.3438 25.2901C62.3438 39.2574 50.9445 50.5802 36.8829 50.5802Z"
        fill="white"
      />
      <g opacity="0.1">
        <path
          d="M49.2578 46.8023C45.4876 49.1384 41.1313 50.4976 36.4552 50.4976C31.7791 50.4976 27.6493 49.2092 23.9457 47.0005L24.9582 41.4081L48.7516 5.0498L49.2578 46.8023Z"
          fill="white"
        />
      </g>
      <path
        d="M25.1152 47.3672L47.6875 9.08984V162.711H25.1152V47.3672Z"
        fill="url(#paint1_linear_7245_37331)"
      />
      <g opacity="0.1">
        <path
          d="M25.1152 139.238L47.6875 106.972V144.425L47.3789 144.906L47.6875 144.946V162.711H25.1152V139.238Z"
          fill="white"
        />
      </g>
      <path
        d="M0 193.92L97.1815 55.1318L191.879 193.92L66.5854 157.267L142.165 159.471L97.1374 94.1057L33.5352 193.92H0Z"
        fill="white"
      />
      <g opacity="0.15">
        <path
          d="M151.966 163.033L97.1375 89.873L29.6262 187.395L0 193.92H33.5352L97.1375 94.1062L142.165 159.471L66.5854 157.267L151.966 163.033Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7245_37331"
          x1="159.55"
          y1="83.8901"
          x2="159.55"
          y2="150.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F3F3" />
          <stop offset="1" stop-color="#F8F8F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7245_37331"
          x1="36.4013"
          y1="31.2844"
          x2="36.4013"
          y2="140.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F2F2F2" />
          <stop offset="1" stop-color="#FAFAFA" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
