import BankAccount from "../models/BankAccount";
export default class PrimaryBankAccount {
  
  public static shared(): PrimaryBankAccount {
    if (!PrimaryBankAccount.sPrimaryBankAccount) {
        PrimaryBankAccount.sPrimaryBankAccount = new PrimaryBankAccount();
    }
    return PrimaryBankAccount.sPrimaryBankAccount;
  }

  private static sPrimaryBankAccount: PrimaryBankAccount | null;
  
  // get wallet user
  private mPrimaryBankAccount: BankAccount | undefined | null;
  public get PrimaryBankAccount() {
    return this.mPrimaryBankAccount;
  }

  // set wallet user
  public setPrimaryBankAccount(PrimaryBankAccount: BankAccount | undefined) {
    this.mPrimaryBankAccount = PrimaryBankAccount
  }

  // update wallet user
  public updatePrimaryBankAccount(updatedPrimaryBankAccount: BankAccount) {
    this.setPrimaryBankAccount(updatedPrimaryBankAccount)
  }

  // reset wallet user
  public resetPrimaryBankAccount() {
    this.setPrimaryBankAccount(undefined)
  }
}
