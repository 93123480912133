import { Box, Button } from "@mui/material";
import loader from "../../../statics/images/loading-gif.gif";
import "./commonBtn.scss";
interface IProps {
  loading?: boolean,
  label: string,
  buttonWidth: string,
  onClickEvent: any,
  disableBtn?: boolean
}
export const PrimaryBtn = (props: IProps) => {
  const flag = props.loading;

  return (
    <Box width={props.buttonWidth}>
      <Button
        variant="contained"
        className="PrimaryBtn"
        disabled={props.disableBtn}
        onClick={props.onClickEvent}
        sx={{
          height: "58px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          fontSize: "20px",
          background: "#000",
          fontFamily: "Bebas",
          letterSpacing: "0.1em",
          fontWeight: "400",
          boxShadow: "none",
          color: "#fff",
          '&:hover': {
            backgroundColor: '#000',
            color: '#fff',
          },
          '&:focus': {
            backgroundColor: '#000',
            color: '#fff',
          },
          '&:disabled': {
            backgroundColor: '#BCBCBC',
            color: '#fff',
          }
        }}
      >
        {flag ? (
          <>
            {`Please wait `}
            <img src={loader} className="buttonLoader" />
          </>
        ) : (
          props.label
        )}
      </Button>
    </Box>
  );
};