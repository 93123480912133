import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import SkeletonLoader, { SkeletonType } from "./SkeletonLoader";
import "./skeletonLoader.scss";

const MainSkeletonLoader = () => {
  return (
    <Box className="mainSkeletonLoader">
      <Box className="sHeader">
        <Box width="400px">
            <SkeletonLoader varient={SkeletonType.BOX} height={100} />
        </Box>
        <Box width="50px">
            <SkeletonLoader varient={SkeletonType.BOX} height={70} />
        </Box>
        
      </Box>
      <Box className="sContentWarpper">
        <Box className="sLeftBar">
          <SkeletonLoader
            boxCount={3}
            varient={SkeletonType.BOX}
            height="60px"
          />
        </Box>
        <Box className="sRightContent">
          <SkeletonLoader
            boxCount={5}
            varient={SkeletonType.BOX}
            height="130px"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default MainSkeletonLoader;
